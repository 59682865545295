const axios = require("axios");

const instance = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default {
  install(Vue) {
    Vue.prototype.$request = instance;
  },
};

// Add a function to update the token

export const updateToken = (token) => {
  instance.defaults.headers["Authorization"] = `Bearer ${token}`;
};
