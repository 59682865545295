import { routes } from "../router";
import { checkPermission } from "./checkPermission";
function buildSidebar() {
  let sidebar = [];
  routes.forEach((route) => {
    if (!route.meta.shown) {
      return;
    }

    if (!route.meta.permission) {
      sidebar.push({
        text: route.name,
        icon: route.meta.icon,
        path: route.path,
      });
      return;
    }

    if (checkPermission(route.meta.permission)) {
      sidebar.push({
        text: route.name,
        icon: route.meta.icon,
        path: route.path,
      });
    }
  });

  return sidebar;
}

export default buildSidebar;
