<template>
  <v-app-bar color="primary" app dark>
    <v-spacer></v-spacer>
    <v-btn icon @click="switchTheme">
      <v-icon v-if="!$vuetify.theme.dark">mdi-moon-waxing-crescent</v-icon>
      <v-icon v-else>mdi-white-balance-sunny</v-icon>
    </v-btn>
    <v-btn @click="openProfile" icon><v-icon>mdi-account</v-icon></v-btn>
    <v-btn @click="logout" icon><v-icon>mdi-logout</v-icon></v-btn>
  </v-app-bar>
</template>

<script>
export default {
  name: "NavBar",

  methods: {
    switchTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem("darkMode", this.$vuetify.theme.dark);
    },
    openProfile() {
      window.open("https://auth.honex.life/realms/master/account");
    },
    logout() {
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      window.location = "/";
    },
  },
};
</script>
