<template>
  <div class="login" :style="dark ? 'background-color: #121212' : ''">
    <div class="container">
      <div class="left">
        <div class="form">
          <div class="input-block">
            <input class="input" type="text" id="email" required="" />
            <label for="email">Username</label>
          </div>
          <div class="input-block">
            <input class="input" type="password" id="pass" required="" />
            <label for="pass">Password</label>
          </div>
          <div class="input-block">
            <span class="forgot"><a href="#">Forgot Password?</a></span>
            <button>Login</button>
            <button @click="openStaff">Staff Login</button>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="img">
          <img
            class="undraw"
            src="@/assets/undraw_fingerprint_login_re_t71l.svg"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "LoginPage",
  data() {
    return {
      dark: false,
      authCode: "",
    };
  },
  mounted() {
    this.dark = localStorage.getItem("darkMode") == "true";
    // get the session state from the url
    this.authCode = new URLSearchParams(window.location.search).get("code");
    if (this.authCode) {
      this.checkToken();
    }
  },
  methods: {
    checkToken() {
      // clean up the url
      window.history.replaceState(
        {},
        document.title,
        "/" + window.location.hash
      );
      axios
        .post("/api/token", {
          code: this.authCode,
        })
        .then((response) => {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("refreshToken", response.data.refreshToken);
          this.$emit("login", true);
        });
    },
    openProfile() {
      this.$router.push("/profile");
    },
    openStaff() {
      window.location = `https://auth.honex.life/realms/master/protocol/openid-connect/auth?response_type=code&client_id=honex-dashboard-sso&redirect_uri=${window.location.origin}&scope=openid%20profile%20email`;
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}
/* From Uiverse.io by sameer2malik */
.container {
  display: flex;
  width: 520px;
  height: 500px;
  max-width: 99%;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 15px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.03);
  border: 0.1px solid rgba(128, 128, 128, 0.178);

  &::after {
    position: absolute;
    content: "";
    width: 80%;
    height: 80%;
    right: -40%;
    background: radial-gradient(
      circle,
      rgba(157, 173, 203, 1) 61%,
      rgba(99, 122, 159, 1) 100%
    );
    border-radius: 50%;
    z-index: -1;
  }
}

.left {
  width: 66%;
  height: 100%;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: relative;
  backdrop-filter: blur(20px);

  &::before {
    position: absolute;
    content: "";
    width: 40%;
    height: 40%;
    right: 1%;
    z-index: -1;
    background: radial-gradient(
      circle,
      rgb(194, 13, 170) 20%,
      rgb(26, 186, 235) 60%,
      rgb(26, 186, 235) 100%
    );
    filter: blur(70px);
    border-radius: 50%;
  }
}

.right {
  width: 34%;
  height: 100%;
}

.img {
  width: 100%;
  height: 100%;

  .undraw {
    width: 100%;
    height: 100%;
  }
}

.input-block {
  position: relative;
}

.input,
button {
  background: transparent;
  outline: none;
  border: 1px solid transparent;
  border-radius: 0.5rem;
  padding: 10px;
  margin: 10px auto;
  width: 80%;
  display: block;
  color: #6d3914;
  font-weight: 500;
  font-size: 1.1em;
  box-shadow: inset 4px 4px 4px rgba(165, 163, 163, 0.315),
    4px 4px 4px rgba(218, 218, 218, 0.13);

  &:focus + label,
  &:valid + label {
    transform: translateY(-120%) scale(0.9);
    transition: all 0.4s;
  }
}

label {
  position: absolute;
  left: 15%;
  top: 37%;
  pointer-events: none;
  color: gray;
}

.forgot {
  display: block;
  margin: 5px 0 10px 35px;
  color: #ab7843;
  font-size: 0.9em;
}

button {
  background-color: #6d3914;
  color: white;
  font-size: medium;
  box-shadow: 2px 4px 8px rgba(70, 70, 70, 0.178);
}

a {
  color: #b8957f;
}
</style>
