<template>
  <v-app v-if="auth" id="inspire">
    <NavBar />

    <SideBar />

    <v-main>
      <v-container class="py-8 px-6" fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>

  <LoginPage v-else @login="tokenCheck" />
</template>

<script>
import axios from "axios";
import LoginPage from "./views/LoginPage.vue";
import NavBar from "./components/NavBar.vue";
import SideBar from "./components/SideBar.vue";
import { updateToken } from "./plugins/request";
export default {
  name: "App",
  components: { LoginPage, NavBar, SideBar },
  data: () => ({
    auth: false,
  }),
  methods: {
    tokenCheck() {
      if (!localStorage.getItem("token")) {
        this.auth = false;
        return;
      }

      let user = this.$getUser();
      if (!user) {
        this.auth = false;
      }

      if (user.exp * 1000 < Date.now()) {
        this.auth = false;
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
      }

      // if (!this.auth) {
      //   return;
      // }

      if (
        user.exp * 1000 - Date.now() < 10000 &&
        user.exp * 1000 - Date.now() > 0
      ) {
        axios
          .post("/api/token/refresh", {
            code: localStorage.getItem("refreshToken"),
          })
          .then((response) => {
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("refreshToken", response.data.refreshToken);
            updateToken(response.data.token);
          })
          .catch((error) => {
            console.log(error);
            this.auth = false;
            return;
          });
      }
      updateToken(localStorage.getItem("token"));

      this.auth = true;
    },
  },
  mounted() {
    updateToken(localStorage.getItem("token"));
    this.tokenCheck();
    setInterval(this.tokenCheck, 1000);
  },
};
</script>
