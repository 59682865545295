import { getUser } from "./userDetail";

function checkPermission(permission) {
  const user = getUser();
  return user
    ? user.resource_access["honex-dashboard-sso"].roles.includes(permission)
    : false;
}

export default {
  install(Vue) {
    Vue.prototype.$checkPermission = checkPermission;
  },
};

export { checkPermission };
