import Vue from "vue";
import { jwtDecode } from "jwt-decode";

function getUser() {
  const token = localStorage.getItem("token");
  if (token) {
    return jwtDecode(token);
  } else {
    return null;
  }
}

Vue.prototype.$getUser = function () {
  const token = localStorage.getItem("token");
  if (token) {
    return jwtDecode(token);
  } else {
    return null;
  }
};

export { getUser };
