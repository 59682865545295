import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,
    themes: {
      light: {
        background: "#FFFFFF",
        surface: "#FFFFFF",
        primary: "#6D3914",
        "primary-darken-1": "#BCA68C",
        secondary: "#4c2b08",
        "secondary-darken-1": "#8E6237",
        accent: "#B8957F",
        error: "#D7BDA6",
        info: "#AB7843",
        success: "#B8957F",
        warning: "#D7BDA6",
      },
      dark: {
        background: "#121212",
        surface: "#212121",
        primary: "#D7BDA6",
        "primary-darken-1": "#BCA68C",
        secondary: "#B8957F",
        "secondary-darken-1": "#8E6237",
        accent: "#B8957F",
        error: "#D7BDA6",
        info: "#AB7843",
        success: "#B8957F",
        warning: "#D7BDA6",
      },
    },
  },
});
