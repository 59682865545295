<template>
  <v-navigation-drawer v-model="drawer" app>
    <v-sheet color="secondary" dark class="pa-4">
      <div class="avatar">
        <img v-if="isHonex" class="image" src="@/assets/logo.png" />
      </div>

      <div v-if="user">{{ user.name }}</div>
    </v-sheet>

    <v-divider></v-divider>

    <v-list v-if="links.length > 0">
      <v-list-item
        v-for="link in links"
        :to="{ path: link.path }"
        :key="link.text"
        link
      >
        <v-list-item-icon>
          <v-icon>{{ link.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ link.text }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import buildSidebar from "@/plugins/buildSidebar";
export default {
  mounted() {
    this.user = this.$getUser();

    if (this.user.azp && this.user.azp === "honex-dashboard-sso") {
      this.isHonex = true;
    }

    this.links = buildSidebar();
  },
  name: "SideBar",
  data: () => ({
    user: null,
    isHonex: false,

    drawer: null,
    links: [],
  }),
};
</script>

<style lang="scss">
.avatar {
  height: 64px;
  width: 64px;
  background-color: #363636;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  .image {
    height: 40px;
    width: 40px;
  }
}
</style>
